import React from 'react';

const About = () => (
  <section id="about" className="bg-white p-8 text-center">
    <h2 className="text-3xl md:text-3xl font-display font-bold mb-4">The Ten Commandments of Cussy</h2>
    <div className="w-full md:w-4/5 lg:w-3/5 mx-auto">
      <p className="text-base md:text-2xl lg:text-2xl font-body mb-4">
        1. Thou shalt not covet thy neighbor's couch<br />
        2. Thou shall use protection while inside couch (rubber glove)<br />
        3. Thou shall not take the name of the Cussy in vain<br />
        4. Thou must receive consent from thy couch<br />
        5. Thou should not enter a leather sofa that has received direct sunlight<br />
        6. Thou shall not hit the sofa from the back on Sunday<br />
        7. Thou shall not sit where you put it in<br />
        8. Thou shall not degrade thine cussy whilst receiving its holy blessing<br />
        9. Thou shall flip the cushions after use<br />
        10. Thou shall tithe 20% of their income to the maintenance of her holiness<br />
      </p>
    </div>
  </section>
);

export default About;
