import React, { useState } from 'react';

const Hero = () => {
  const [copied, setCopied] = useState(false);

  const contractAddress = "3adRrkuJmUWb1bCwZDavvewURgZqCnSFhDQPNS8hpump";

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <section id="home" className="relative bg-white text-gray-800 p-4 sm:p-8 text-center overflow-hidden">
      <img 
        src="/cussy.jpg" 
        alt="cussy" 
        className="mx-auto rounded-lg shadow-lg mb-4 w-full sm:w-3/4 md:w-2/3 lg:w-1/2 max-w-xs md:max-w-md border-4 border-black z-10" 
      />
      <h1 className="text-3xl sm:text-4xl md:text-5xl font-display font-light mb-3 text-black-700 relative z-10">$Cussy</h1>
      <p className="text-lg sm:text-xl md:text-2xl font-body text-black relative z-10">JD Vance was here! And he FUCKED the couch cussy!</p>
      <div className="flex flex-wrap justify-center mt-8 space-x-2 sm:space-x-4 relative z-10">
        <a href="https://dexscreener.com/solana/3lyazjawjrp7j3f821dvpt396hwpzjrkmh2a6vbxdahn" target="_blank" rel="noopener noreferrer">
          <img src="/buttons/dexscreener.png" alt="Dexscreener" className="h-8 sm:h-10 md:h-12 rounded-lg" />
        </a>
        <a href="https://pump.fun/3adRrkuJmUWb1bCwZDavvewURgZqCnSFhDQPNS8hpump" target="_blank" rel="noopener noreferrer">
          <img src="/buttons/pumpfun.webp" alt="Raydium" className="h-8 sm:h-10 md:h-12 border-4 border-black rounded-lg" />
        </a>
        <a href="https://t.me/C_U_S_S_Y_CTO" target="_blank" rel="noopener noreferrer">
          <img src="/buttons/tg.svg" alt="Telegram" className="h-8 sm:h-10 md:h-12 rounded-lg" />
        </a>
        <a href="https://x.com/cussy_sol" target="_blank" rel="noopener noreferrer">
          <img src="/buttons/tw.svg" alt="Twitter" className="h-8 sm:h-10 md:h-12 border-4 border-black rounded-lg" />
        </a>
      </div>
      <div className="mt-8 relative z-10">
        <div 
          className="inline-block px-2 py-1 bg-gray-200 rounded-lg border-4 border-black"
          onClick={handleCopy}
          style={{ cursor: 'pointer', transition: 'background-color 0.3s' }}
        >
          <span className="text-xs sm:text-sm md:text-lg font-bold text-black">
            {copied ? 'Copied!' : 'CA: ' + contractAddress}
          </span>
        </div>
      </div>
      <div className="mt-8 relative z-10">
        <a href="https://cussy-tinder.vercel.app/" target="_blank" rel="noopener noreferrer" className="inline-block px-4 py-2 bg-red-500 text-white rounded-lg border-4 border-black font-bold text-lg sm:text-xl md:text-2xl">
          Find Love
        </a>
      </div>
    </section>
  );
};

export default Hero;
